import { Injectable } from '@angular/core';
import { ActionButton, ButtonType, LayoutConfig, LayoutConfigService } from '@shared/services/layout-config.service';
import { AppRoutes, ManagedRoutes } from '@core/enums/routes.enum';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { EntityConfig, getPermissionGroup, getUserFriendlyName } from '@core/models/entity-config.model';
import { DestroyBase } from '@core/base/destroy.class';
import { DestructureHelper } from '@core/utils/destructure.helper';
import { TitleHelper } from '@core/utils/title.helper';
import { IEntityBase } from '@core/interfaces/entity-base';
import { Entity } from '@core/enums/entity.enum';

@Injectable({
  providedIn: 'root',
})
export class OverviewConfigService<EntityModel extends IEntityBase> extends DestroyBase {
  public static getBackUrl(entity: Entity): string | string[] {
    const isManaged = Object.values(ManagedRoutes)
      .map((value) => {
        return value.toString();
      })
      .includes(entity);

    if (isManaged) {
      return ['/', AppRoutes.admin, AppRoutes.manage];
    } else if (AppRoutes[entity]) {
      return ['/', AppRoutes.admin, AppRoutes[entity]];
    }
  }

  constructor(
    private layoutConfigService: LayoutConfigService,
    private translateService: TranslateService,
    private router: Router,
  ) {
    super();
  }

  public generateAddUrl(entityConfig: EntityConfig): string | string[] {
    return ['/', AppRoutes.admin, getUserFriendlyName(entityConfig), AppRoutes.add];
  }

  public generateAddButton(entityConfig: EntityConfig): ActionButton {
    return {
      type: ButtonType.ADD,
      label: `dxp.${entityConfig.name}.add`,
      icon: 'fas fa-plus',
      styleClass: 'primary',
      permissions: [`dxp.${getPermissionGroup(entityConfig)}.manage`],
      route: this.generateAddUrl(entityConfig),
    };
  }

  public generateDefaultConfig(item: EntityModel, entityConfig: EntityConfig): LayoutConfig {
    return {
      title: TitleHelper.concatTitles(
        this.layoutConfigService.generateDefaultTitleTranslationKeys(entityConfig),
        ' - ',
      ),
      url: this.router.url,
      subtitle: this.translateService.stream('dxp.subtitle.overview'),
      backUrl: OverviewConfigService.getBackUrl(entityConfig.name),
      actionButtons: [this.generateAddButton(entityConfig)],
    } as LayoutConfig;
  }

  public overwriteAddButton(buttons: ActionButton[], options: Partial<ActionButton>): ActionButton[] {
    return [DestructureHelper.findAndOverwriteAttributes<ActionButton, ButtonType>(buttons, [ButtonType.ADD], options)];
  }
}
